export default {
    "chief_types": {
        "direct": {
            "showName": "Непосредственный руководитель"
        },
        "functional": {
            "showName": "Функциональный руководитель"
        }
    },
    "my_chiefs_types": {
        "direct": [],
        "functional": []
    },
    "my_employees_types": {
        "direct": [],
        "functional": []
    },
    "users_search_criteria": [
        "app\\domain\\User\\criteria\\UsersFiredCriteria"
    ],
    "users_visible_permissions_criteria_map": {
        "user.see_self": "app\\domain\\User\\criteria\\returnInNewQuery\\UserSelfCriteria",
        "user.see_subordinates": "app\\domain\\User\\criteria\\returnInNewQuery\\UserAllSubordinatesCriteria",
        "user.see_chiefs": "app\\domain\\User\\criteria\\returnInNewQuery\\UserChiefsCriteria"
    },
    "users_list_columns": [
        {
            "label": "Перс. №",
            "prop": "persnum"
        },
        {
            "label": "Email",
            "prop": "email"
        },
        {
            "label": "ФИО",
            "prop": "full_name"
        },
        {
            "label": "Должность",
            "prop": "position"
        },
        {
            "label": "Подразделение",
            "prop": "subdivision"
        },
        {
            "label": "Компания",
            "prop": "company"
        }
    ],
    "users_list_default_role_filter_in_search": "user",
    "filters": {
        "criteria": []
    },
    "sort_params": {
        "attributes": {
            "0": "persnum",
            "1": "company",
            "2": "email",
            "3": "position",
            "4": "subdivision",
            "5": "company",
            "full_name": {
                "asc": {
                    "last_name": 4
                },
                "desc": {
                    "last_name": 3
                }
            }
        },
        "defaultOrder": {
            "full_name": 4
        }
    },
    "chiefs_list_columns": [
        {
            "label": "Перс. №",
            "prop": "user.persnum"
        },
        {
            "label": "Email",
            "prop": "user.email"
        },
        {
            "label": "ФИО",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Подразделение",
            "prop": "user.subdivision"
        },
        {
            "label": "Компания",
            "prop": "user.company"
        },
        {
            "label": "Перс. № руководителя",
            "prop": "chief.persnum"
        },
        {
            "label": "Руководитель",
            "prop": "chief.full_name"
        }
    ],
    "chiefs_search_fields": {
        "users.last_name": {
            "relation": "user"
        },
        "users.first_name": {
            "relation": "user"
        },
        "users.middle_name": {
            "relation": "user"
        },
        "users.position": {
            "relation": "user"
        },
        "chiefs.last_name": {
            "relation": "chief chiefs"
        },
        "chiefs.first_name": {
            "relation": "chief chiefs"
        },
        "chiefs.middle_name": {
            "relation": "chief chiefs"
        }
    },
    "chiefs_sort_params": {
        "attributes": {
            "user.persnum": {
                "asc": {
                    "users.persnum": 4
                },
                "desc": {
                    "users.persnum": 3
                }
            },
            "user.email": {
                "asc": {
                    "users.email": 4
                },
                "desc": {
                    "users.email": 3
                }
            },
            "user.full_name": {
                "asc": {
                    "users.last_name": 4
                },
                "desc": {
                    "users.last_name": 3
                }
            },
            "user.company": {
                "asc": {
                    "users.company": 4
                },
                "desc": {
                    "users.company": 3
                }
            },
            "user.subdivision": {
                "asc": {
                    "users.subdivision": 4
                },
                "desc": {
                    "users.subdivision": 3
                }
            },
            "user.position": {
                "asc": {
                    "users.position": 4
                },
                "desc": {
                    "users.position": 3
                }
            }
        },
        "defaultOrder": {
            "user.full_name": 4
        }
    }
}